<template>
  <div>
    <WaterVehicleRequestComp />
  </div>
</template>

<script>
export default {
  name: "WaterVehicleRequest",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Water Vehicle Requests", path:"", isActive: true}] 
    }
  },
  components: {
    WaterVehicleRequestComp: () =>
      import("../components/WaterVehicleRequest/WaterVehicleRequest"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>